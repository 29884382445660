import React from "react";
import "./Products.scss";
import ShopProducts from "../../components/ShopProducts/ShopProducts";
import SEO from "../../components/SEO/SEO";

const Products = () => {
  return (
    <div className="products">
      <SEO
        title="NATAN DE WALIÈRE | SHOP"
        description="Welcome to our online shop featuring a captivating collection of limited digital prints showcasing the extraordinary artworks of Natan de Walière. Discover a world of artistic brilliance as you browse through our exclusive selection of limited edition pieces. Elevate your living space with the allure of Natan de Walière's creations, each thoughtfully crafted to inspire and mesmerize. Don't miss the opportunity to own a unique masterpiece – explore our shop now and indulge in the beauty of limited edition art. Shop with us and embrace the art of exclusivity!"
      />
      <div className="container">
        <div className="attributes">
          <h2>limited quantity</h2>
          <h2>fine art prints</h2>
        </div>
      </div>
      <ShopProducts />
    </div>
  );
};

export default Products;
