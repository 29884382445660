import React from "react";
import "./Contact.scss";
import SEO from "../../components/SEO/SEO";

const Contact = () => {
  return (
    <div className="contact">
      <SEO
        title="NATAN DE WALIÈRE | CONTACT"
        description="Get in touch with the Natan de Walière team through our Contact page. We value your inquiries, feedback, and collaboration opportunities. Whether you have questions about our limited digital prints, shipping, or any other aspect of our online shop, we're here to assist you. Connect with us and experience personalized customer support as we strive to make your artistic journey exceptional. Reach out today and let's create a meaningful connection in the world of art."
      />
      <h1>Any questions?</h1>
      <p>Just ask.</p>
      <a href="mailto:info@dewaliere.com">info@dewaliere.com</a>
    </div>
  );
};

export default Contact;
