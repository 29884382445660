import React, { useEffect, useState } from 'react'
import "./ShopProducts.scss"
import Card from '../Card/Card'
import axios from "axios"

const ShopProducts = ({type}) => {

    const [products, setProducts] = useState([])

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const res = await axios.get(
                    process.env.REACT_APP_API_URL + `/products?populate=*`,
                    {
                    headers: {
                        Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
                    },
                    }
                );
                setProducts(res.data.data)
            } catch (err) {
                console.log(err)
            }
        };
        fetchProducts();
    }, []);


  return (
    <div className='shopProducts'>
          <div className="section">
              {products.map(item => (
                  <Card item={item} key={item.id}/>
              ))}
          </div>
    </div>
  )
}

export default ShopProducts