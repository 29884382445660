import React from "react";
import "./About.scss";
import SEO from "../../components/SEO/SEO";

const About = () => {
  return (
    <div className="about">
      <SEO
        title="NATAN DE WALIÈRE | ABOUT"
        description="At Natan de Walière, we are passionate about art and believe in the power of creativity to inspire and transform lives. Our About section is a glimpse into the heart and soul of our online shop, dedicated to curating and sharing the exceptional talent of artist Natan de Walière. Learn more about the artist's journey, vision, and artistic process as we strive to bring you closer to the essence of each captivating masterpiece. Discover the story behind the limited digital prints, and immerse yourself in the world of artistic brilliance that defines Natan de Walière. Join us on this artistic journey, where passion and creativity unite to create a truly extraordinary experience for art enthusiasts worldwide."
      />
      <div className="container">
        <div className="intro">
          <h1>Inspired by the wonders of our world</h1>
          <div className="info">
            <p>
              My creative process is a dance, a rhythmic flow that weaves
              between disorder and structure, creating a unique playground for
              exploration in each of my works. I thrive in the unpredictability
              of artistic expression, allowing preconceived notions to fade away
              as the energy of the moment guides me. Embracing the untamed and
              uncontrolled aspects of my art, I find beauty in the rawness of
              the creative journey, where imperfections become a vital part of
              the narrative, telling stories of spontaneity and freedom.
              Liberated from the constraints of traditional norms, I revel in
              the chaos, constantly seeking a sense of liberation
            </p>
            <p>
              As I delve deeper into the process, layers of order begin to
              emerge amidst the chaos. Shapes materialize, lines intersect, and
              the composition takes form. Within the disorder, a subtle harmony
              surfaces, striking a delicate balance that captivates the eye.
            </p>
            <p>
              The unpredictable and uncharted territory of artistic
              experimentation provide me solace and comfort. It is within this
              space that I flourish as an artist, constantly pushing boundaries
              and redefining what is possible. The fusion of chaos and harmony
              culminates in a visual symphony, resonating not only within myself
              but also with my audience.
            </p>
            <p>
              My art extends an invitation to embrace imperfection and celebrate
              the beauty of spontaneity and the unconventional. I aspire for
              those who view my work to find inspiration in the unbridled spirit
              of creation, encouraging them to fearlessly embrace their own
              creative instincts. Together, let us revel in the captivating
              allure of unpredictability and find solace in the dance between
              disorder and structure.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
