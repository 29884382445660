import React, { useState } from "react";
import "./Shipping.scss";

const Shipping = () => {
  const addWorkdays = (date, daysToAdd) => {
    const copyDate = new Date(date);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      copyDate.setDate(copyDate.getDate() + 1);
      if (copyDate.getDay() !== 0 && copyDate.getDay() !== 6) {
        // Check if the day is not Sunday (0) and not Saturday (6)
        addedDays++;
      }
    }

    return copyDate;
  };

  // Get the current date
  const currentDate = new Date();

  // Define delivery times for different shipping methods (in working days)
  const deliveryTimes = {
    standard: 14,
    express: 10,
  };

  // Define prices for different shipping methods
  const deliveryPrices = {
    standard: "free",
    express: "20€",
  };

  // Calculate the dates for different shipping methods (unchanged)
  const standardDate = addWorkdays(currentDate, deliveryTimes.standard);
  const expressDate = addWorkdays(currentDate, deliveryTimes.express);

  // State to track which section is active (Methods or Countries)
  const [activeSection, setActiveSection] = useState("Methods");

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="shipping">
      <h1>Delivery</h1>
      <div className="container">
        <div className="buttons">
          <h2
            onClick={() => handleSectionClick("Methods")}
            style={{ color: activeSection === "Methods" ? "#000" : "#999" }}
          >
            Methods
          </h2>
          <h2
            onClick={() => handleSectionClick("Countries")}
            style={{ color: activeSection === "Countries" ? "#000" : "#999" }}
          >
            Countries
          </h2>
        </div>
        <div
          className="left"
          style={{ display: activeSection === "Methods" ? "block" : "none" }}
        >
          <div className="method">
            <div className="data">
              <h3>Standard shipping</h3>
              <h3>{deliveryPrices.standard}</h3>
            </div>
            <p>
              Delivery on or before{" "}
              <span>{standardDate.toLocaleDateString()}</span>
            </p>
            <p className="notice">
              Note: The indicated dates are dependent on the completion of the
              order within certain deadlines and will be confirmed after the
              payment process.
            </p>
          </div>
          <div className="method">
            <div className="data">
              <h3>Express shipping</h3>
              <h3>{deliveryPrices.express}</h3>
            </div>
            <p>
              Delivery on or before{" "}
              <span>{expressDate.toLocaleDateString()}</span>
            </p>
            <p className="notice">
              Note: The indicated dates are dependent on the completion of the
              order within certain deadlines and will be confirmed after the
              payment process.
            </p>
          </div>
        </div>
        <div
          className="right"
          style={{ display: activeSection === "Countries" ? "block" : "none" }}
        >
          <div className="method">
            <div className="data">
              <h3>Countries we deliver to:</h3>
            </div>
            <p>
              Austria, Belgium, Czech Republic, Denmark, France, Germany,
              Luxembourg, Netherlands, Poland, Switzerland
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
