import React from "react";
import "./Terms.scss";

const Terms = () => {
  return (
    <div className="terms">
      <h1>Terms & Conditions</h1>
      <h2>§1 Scope of application for entrepreneurs and definitions</h2>
      <p>
        {" "}
        (1) The following General Terms and Conditions apply to all deliveries
        between us and a consumer in their valid version at the time of the
        order.
        <br />
        <br />A consumer is any natural person who enters into a legal
        transaction for purposes that can predominantly neither be attributed to
        their commercial nor their self-employed professional activity.
      </p>
      <h2>§2 Conclusion of contract, storage of the contract text</h2>
      <p>
        {" "}
        (1) The following provisions regarding the conclusion of the contract
        apply to orders through our online shop https://www.dewaliere.com.
        <br />
        <br />
        (2) In the event of the conclusion of the contract, the contract is
        concluded with
        <br />
        <br />
        Adrian Walenta Kunstvertrieb
        <br />
        Kässbohrerstr. 16
        <br />
        D-89077 Ulm
        <br />
        <br />
        (3) The presentation of the goods in our online shop does not constitute
        a legally binding contract offer on our part, but is only a non-binding
        invitation to the consumer to order goods. By placing an order for the
        desired goods, the consumer makes a binding offer to conclude a purchase
        contract.
        <br />
        <br />
        The acceptance of the offer is made in writing or in text form or by
        sending the ordered goods within one week. After the expiration of the
        period without acceptance, the offer is considered rejected.
        <br />
        <br />
        (4) The following regulations apply when ordering through our online
        shop: The consumer submits a binding contract offer by successfully
        completing the order process provided in our online shop.
        <br />
        <br />
        The order process includes the following steps:
        <br />
        <br />
        1) Selection of the desired goods
        <br />
        2) Confirmation by clicking the "Add to cart" button
        <br />
        3) Review of the information in the shopping cart
        <br />
        4) Clicking the "Proceed to checkout" button
        <br />
        5) Forwarding to the payment service provider Stripe
        <br />
        6) Providing all necessary payment and personal data
        <br />
        7) Binding submission of the order by clicking the "Pay" button
        <br />
        <br />
        Before submitting the order, the consumer can use the "Back" button
        contained in their internet browser to return to the page where the
        customer's information is recorded and correct any input errors or
        cancel the ordering process by closing the internet browser. We confirm
        receipt of the order immediately by an automatically generated email.
        This does not yet constitute acceptance of the offer. Acceptance of the
        offer is made in writing, in text form, or by sending the ordered goods
        within one week. (5) Storage of the contract text for orders through our
        online shop: We will send you the order data and our General Terms and
        Conditions by email. You can also view the General Terms and Conditions
        at any time at https://www.dewaliere.com/terms-conditions. For security
        reasons, your order data is no longer accessible via the internet.
      </p>
      <h2>§3 Prices, shipping costs, payment, due date</h2>
      <p>
        {" "}
        (1) The prices stated include all components of the price. Any shipping
        costs will be added.
        <br />
        <br />
        (2) The consumer has the option of payment via PayPal, credit card
        (Visa, Mastercard, American Express), and Klarna. All payment services
        are provided by Stripe.
      </p>
      <h2>§4 Delivery</h2>
      <p>
        {" "}
        (1) Unless otherwise clearly stated in the product description, all
        items offered by us are ready for immediate shipment. Delivery within
        Germany takes place within 14 working days at the latest. Delivery to
        all countries outside of Germany takes place within 21 days. The
        deadline for delivery begins on the day after the contract is concluded.
        If the deadline ends on a Saturday, Sunday, or public holiday at the
        place of delivery, the deadline ends on the next working day.
        <br />
        <br />
        (2) The risk of accidental loss and accidental deterioration of the sold
        item also passes to the buyer in the case of mail-order purchases when
        the item is handed over to the buyer.
      </p>
      <h2>§5 Retention of title</h2>
      <p>
        {" "}
        We reserve ownership of the goods until the purchase price has been
        fully paid.
      </p>
      <h2>§6 Right of withdrawal of the customer as a consumer:</h2>
      <p>
        {" "}
        <b>Right of withdrawal for consumers</b>
        <br />
        <br />
        Consumers have a right of withdrawal under the following conditions,
        where consumers are any natural person who enters into a legal
        transaction for purposes that can predominantly neither be attributed to
        their commercial nor their self-employed professional activity:
        <br />
        <br />
        <b>Right of withdrawal</b>
        <br />
        <br />
        You have the right to withdraw from this contract within fourteen days
        without giving any reason.
        <br />
        <br />
        The withdrawal period is fourteen days from the day on which you or a
        third party named by you, who is not the carrier, have taken possession
        of the goods.
        <br />
        <br />
        To exercise your right of withdrawal, you must inform us
        <br />
        <br />
        Adrian Walenta Kunstvertrieb
        <br />
        Kässbohrerstr. 16
        <br />
        D-89077 Ulm
        <br />
        E-Mail: info@dewaliere.com
        <br />
        <br />
        by means of a clear declaration (e.g. a letter sent by post, fax, or
        email) of your decision to withdraw from this contract. You can use our{" "}
        <a href="/return#withdrawalform">sample withdrawal form</a>, which is
        not mandatory, for this purpose.
        <br />
        <br />
        <b>Consequences of withdrawal</b>
        <br />
        <br />
        If you withdraw from this contract, we will refund all payments we have
        received from you, including delivery costs (except for the additional
        costs arising from your choosing a different type of delivery than the
        most favorable standard delivery offered by us), without undue delay and
        no later than fourteen days from the day on which we receive notice of
        your withdrawal from this contract. For this refund, we will use the
        same means of payment that you used for the original transaction, unless
        expressly agreed otherwise with you; in no case will you be charged for
        this refund.
        <br />
        <br />
        We may refuse to make a refund until we have received the goods back or
        until you have provided proof that you have returned the goods,
        whichever is the earlier.
        <br />
        <br />
        You must return or hand over the goods to us immediately and in any case
        no later than fourteen days from the day on which you inform us of the
        withdrawal from this contract. The deadline is met if you send the goods
        before the fourteen-day deadline has expired.
        <br />
        <br />
        You bear the direct costs of returning the goods.
      </p>
      <h2>§7 Warranty</h2>
      <p>The statutory warranty provisions apply. As of August 2023</p>
    </div>
  );
};

export default Terms;
