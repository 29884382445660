import { useEffect, useState } from "react";
import { makeRequest } from "../makeRequest";

const useFetch =(url) => {
    
    const [products, setProducts] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const res = await makeRequest.get(url);
                setProducts(res.data.data);
            } catch (err) {
                setError(true);
            }
            setLoading(false);
        };
        fetchProducts();
    }, [url]);

    return { products, loading, error }
};

export default useFetch;