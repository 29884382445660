import React from "react";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <h2>1. Privacy at a Glance</h2>
      <h3>General Information</h3>
      <p>
        The following information provides a simple overview of what happens to
        your personal data when you visit this website. Personal data includes
        all data that can personally identify you. Detailed information about
        data protection can be found in our privacy policy listed below this
        text.
      </p>
      <h3>Data Collection on this Website</h3>
      <h4>Who is responsible for data collection on this website?</h4>
      <p>
        Data processing on this website is carried out by the website operator.
        You can find the operator's contact details in the "Controller
        Information" section of this privacy policy.
      </p>
      <h4>How do we collect your data?</h4>
      <p>
        Your data is collected when you provide it to us. This may include data
        you enter into a contact form, for example.
      </p>
      <p>
        Other data is automatically collected or obtained with your consent when
        you visit the website through our IT systems. This includes technical
        data such as internet browsers, operating systems, or the time of page
        access. This data is collected automatically as soon as you enter this
        website.
      </p>
      <h4>What do we use your data for?</h4>
      <p>
        Some of the data is collected to ensure the error-free provision of the
        website. Other data may be used for analyzing your user behavior.
      </p>
      <h4>What rights do you have regarding your data?</h4>
      <p>
        You have the right to request free information about the origin,
        recipients, and purpose of your stored personal data at any time. You
        also have the right to request the correction or deletion of this data.
        If you have given consent for data processing, you can revoke this
        consent at any time for the future. Furthermore, under certain
        circumstances, you have the right to request the restriction of
        processing your personal data. Additionally, you have the right to lodge
        a complaint with the competent supervisory authority.
      </p>
      <p>
        For this and any other questions regarding data protection, you can
        contact us at any time.
      </p>
      <h3>Analysis Tools and Third-Party Tools</h3>
      <p>
        When visiting this website, your surfing behavior may be statistically
        analyzed. This is mainly done with so-called analysis programs.
      </p>
      <p>
        Detailed information about these analysis programs can be found in the
        following privacy policy.
      </p>
      <h2>2. Hosting</h2>
      <p>We host the content of our website with the following provider:</p>
      <h3>IONOS</h3>
      <p>
        The provider is IONOS SE, Elgendorfer Str. 57, 56410 Montabaur
        (hereinafter referred to as IONOS). When you visit our website, IONOS
        collects various log files, including your IP addresses. For details,
        please refer to IONOS' privacy policy:
        <a
          href="https://www.ionos.de/terms-gtc/terms-privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.ionos.de/terms-gtc/terms-privacy
        </a>
        .
      </p>
      <p>
        The use of IONOS is based on Article 6(1)(f) of the GDPR. We have a
        legitimate interest in ensuring a reliable presentation of our website.
        If consent is requested, the processing is based solely on Article
        6(1)(a) of the GDPR and § 25(1) of the TTDSG, to the extent that the
        consent includes the storage of cookies or access to information on the
        user's terminal device (e.g., device fingerprinting) within the meaning
        of the TTDSG. Consent can be revoked at any time.
      </p>
      <h4>Data Processing Agreement (Auftragsverarbeitung)</h4>
      <p>
        We have entered into a data processing agreement (Auftragsverarbeitung)
        for the use of the service mentioned above. This is a legally required
        contract that ensures that the service processes the personal data of
        our website visitors only according to our instructions and in
        compliance with the GDPR.
      </p>
      <h2>3. General Information and Mandatory Information</h2>
      <h3>Data Protection</h3>
      <p>
        The operators of this website take the protection of your personal data
        very seriously. We treat your personal data confidentially and in
        accordance with the legal data protection regulations and this privacy
        policy.
      </p>
      <p>
        When you use this website, various personal data is collected. Personal
        data is data that can personally identify you. This privacy policy
        explains what data we collect and for what purpose we use it. It also
        explains how and for what purpose this is done.
      </p>
      <p>
        We would like to point out that data transmission over the Internet
        (e.g., when communicating via email) may have security vulnerabilities.
        A complete protection of the data from access by third parties is not
        possible.
      </p>
      <h3>Controller Information</h3>
      <p>The controller responsible for data processing on this website is:</p>
      <p>
        Adrian Walenta Kunstvertrieb
        <br />
        Kässbohrerstr. 16
        <br />
        89077 Ulm
        <br />
        Germany
      </p>
      <p>
        Phone: +49 176 6370 8475
        <br />
        Email: info@dewaliere.com
      </p>
      <p>
        The responsible entity is the natural or legal person who, alone or
        jointly with others, decides on the purposes and means of processing
        personal data (e.g., names, email addresses, etc.).
      </p>
      <h3>Data Retention Period</h3>
      <p>
        Unless a specific storage period has been mentioned in this privacy
        policy, your personal data will remain with us until the purpose for
        data processing no longer applies. If you assert a legitimate request
        for deletion or revoke your consent for data processing, your data will
        be deleted unless we have other legally permissible reasons for storing
        your personal data (e.g., tax or commercial retention periods); in the
        latter case, deletion will occur once these reasons no longer apply.
      </p>
      <h3>
        General Information about the Legal Basis for Data Processing on this
        Website
      </h3>
      <p>
        If you have given your consent for data processing, we process your
        personal data based on Article 6(1)(a) of the GDPR or Article 9(2)(a) of
        the GDPR, if special categories of data are processed under Article 9(1)
        of the GDPR. In case of explicit consent to transfer personal data to
        third countries, data processing is also based on Article 49(1)(a) of
        the GDPR. If you have given your consent for the storage of cookies or
        access to information on your device (e.g., via device fingerprinting),
        data processing additionally takes place based on § 25(1) of the TTDSG.
        Consent can be revoked at any time.
      </p>
      <p>
        If your data is necessary for the fulfillment of a contract or the
        implementation of pre-contractual measures, we process your data based
        on Article 6(1)(b) of the GDPR. Furthermore, we process your data if
        required for the fulfillment of a legal obligation based on Article
        6(1)(c) of the GDPR. Data processing may also occur based on our
        legitimate interest under Article 6(1)(f) of the GDPR. The relevant
        legal bases in each specific case will be provided in the following
        sections of this privacy policy.
      </p>
      <h3>Recipients of Personal Data</h3>
      <p>
        As part of our business activities, we work with various external
        entities. In some cases, the transmission of personal data to these
        external entities is necessary. We only disclose personal data to
        external entities when it is necessary for contract fulfillment, when we
        are legally obligated to do so (e.g., data transfer to tax authorities),
        when we have a legitimate interest under Article 6(1)(f) of the GDPR in
        the disclosure, or when another legal basis allows the data transfer.
        When using data processors, we only disclose personal data of our
        customers based on a valid data processing agreement. In case of joint
        processing, a joint processing agreement will be concluded.
      </p>
      <h3>Revocation of Your Consent for Data Processing</h3>
      <p>
        Many data processing operations are only possible with your explicit
        consent. You can revoke your consent at any time. The legality of data
        processing prior to revocation remains unaffected by the revocation.
      </p>
      <h3>
        Right to Object to Data Collection in Special Cases and Direct Marketing
        (Article 21 of the GDPR)
      </h3>
      <p>
        IF DATA PROCESSING IS BASED ON ARTICLE 6(1)(e) OR (f) OF THE GDPR, YOU
        HAVE THE RIGHT TO OBJECT, ON GROUNDS RELATING TO YOUR PARTICULAR
        SITUATION, TO THE PROCESSING OF YOUR PERSONAL DATA, INCLUDING PROFILING
        BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH A
        PROCESSING IS BASED CAN BE FOUND IN THIS PRIVACY POLICY. IF YOU OBJECT,
        WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA UNLESS WE CAN
        DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING WHICH
        OVERRIDE YOUR INTERESTS, RIGHTS, AND FREEDOMS, OR THE PROCESSING IS FOR
        THE ESTABLISHMENT, EXERCISE, OR DEFENSE OF LEGAL CLAIMS (OBJECTION
        PURSUANT TO ARTICLE 21(1) OF THE GDPR).
      </p>
      <p>
        IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT MARKETING PURPOSES, YOU
        HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF YOUR PERSONAL
        DATA FOR SUCH MARKETING, INCLUDING PROFILING TO THE EXTENT THAT IT IS
        RELATED TO SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL
        SUBSEQUENTLY NO LONGER BE USED FOR DIRECT MARKETING PURPOSES (OBJECTION
        PURSUANT TO ARTICLE 21(2) OF THE GDPR).
      </p>
      <h3>
        Right to Lodge a Complaint with the Competent Supervisory Authority
      </h3>
      <p>
        In the event of violations of the GDPR, data subjects have the right to
        lodge a complaint with a supervisory authority, especially in the Member
        State of their habitual residence, place of work, or the place of the
        alleged infringement. The right to lodge a complaint is without
        prejudice to other administrative or judicial remedies.
      </p>
      <h3>Right to Data Portability</h3>
      <p>
        You have the right to receive data that we process automatically based
        on your consent or in fulfillment of a contract, in a common,
        machine-readable format, and to have that data transmitted to yourself
        or another controller, where technically feasible, upon your request.
      </p>
      <h3>Information, Correction, and Deletion</h3>
      <p>
        You have the right, under the applicable legal provisions, to obtain
        free information about your stored personal data, its origin,
        recipients, and the purpose of data processing, and, if necessary, the
        right to correct or delete this data. For this purpose, as well as for
        any other questions regarding personal data, you can always contact us.
      </p>
      <h3>Right to Restriction of Processing</h3>
      <p>
        You have the right to request the restriction of processing of your
        personal data. You can contact us at any time to exercise this right.
        The right to restrict processing applies in the following cases:
      </p>
      <ul>
        <li>
          If you contest the accuracy of your personal data stored by us, we
          usually need time to verify this. During the verification period, you
          have the right to request the restriction of processing of your
          personal data.
        </li>
        <li>
          If the processing of your personal data was/is unlawful, you can
          request the restriction of data processing instead of deletion.
        </li>
        <li>
          If we no longer need your personal data, but you need it for the
          exercise, defense, or establishment of legal claims, you have the
          right to request the restriction of processing of your personal data
          instead of deletion.
        </li>
        <li>
          If you have filed an objection pursuant to Article 21(1) of the GDPR,
          a balance must be struck between your interests and ours. Until it is
          determined whose interests prevail, you have the right to request the
          restriction of processing of your personal data.
        </li>
      </ul>
      <p>
        If you have restricted the processing of your personal data, these data
        – with the exception of storage – may only be processed with your
        consent, or for the establishment, exercise, or defense of legal claims,
        for the protection of the rights of another natural or legal person, or
        for reasons of important public interest of the European Union or a
        Member State.
      </p>
      <h3>SSL/TLS Encryption</h3>
      <p>
        For security reasons and to protect the transmission of confidential
        content, such as orders or inquiries that you send to us as the site
        operator, this site uses SSL/TLS encryption. You can recognize an
        encrypted connection by the change in the address line of your browser
        from "http://" to "https://" and the lock symbol in your browser line.
      </p>
      <p>
        When SSL/TLS encryption is activated, the data you transmit to us cannot
        be read by third parties.
      </p>
      <h3>Encrypted Payment Transactions on this Website</h3>
      <p>
        If, after the conclusion of a paid contract, there is an obligation to
        transmit your payment data (e.g., account number for direct debit
        authorization), this data is required for payment processing.
      </p>
      <p>
        Payment transactions via common payment methods (Visa/MasterCard, direct
        debit) are processed exclusively via an encrypted SSL/TLS connection.
        You can recognize an encrypted connection by the change in the address
        line of your browser from "http://" to "https://" and the lock symbol in
        your browser line.
      </p>
      <p>
        With encrypted communication, your payment data that you transmit to us
        cannot be read by third parties.
      </p>
      <h2>4. Data Collection on this Website</h2>
      <h3>Cookies</h3>
      <p>
        Our website uses so-called "cookies." Cookies are small data packets and
        do not cause any harm to your end device. They are either stored
        temporarily for the duration of a session (session cookies) or
        permanently (persistent cookies) on your end device. Session cookies are
        automatically deleted after your visit ends. Persistent cookies remain
        stored on your end device until you delete them yourself or they are
        automatically deleted by your web browser.
      </p>
      <p>
        Cookies can come from us (first-party cookies) or from third-party
        companies (so-called third-party cookies). Third-party cookies enable
        the integration of certain services from third-party companies within
        websites (e.g., cookies for payment processing services).
      </p>
      <p>
        Cookies serve various functions. Numerous cookies are technically
        necessary because certain website functions would not work without them
        (e.g., the shopping cart function or video display). Other cookies may
        be used to analyze user behavior or for advertising purposes.
      </p>
      <p>
        Cookies that are necessary for the electronic communication process, to
        provide certain functions you desire (e.g., for the shopping cart
        function), or to optimize the website (e.g., cookies for measuring web
        audiences) are stored based on Article 6(1)(f) of the GDPR, unless
        another legal basis is specified. The website operator has a legitimate
        interest in storing necessary cookies for the technically error-free and
        optimized provision of its services. If consent to the storage of
        cookies and similar identification technologies has been requested, the
        processing is based exclusively on this consent (Article 6(1)(a) of the
        GDPR and Section 25(1) of the TTDSG); the consent can be revoked at any
        time.
      </p>
      <p>
        You can configure your browser settings to be informed about the setting
        of cookies and allow cookies only in individual cases, exclude the
        acceptance of cookies for specific cases, or generally, and activate the
        automatic deletion of cookies when closing the browser. If cookies are
        deactivated, the functionality of this website may be limited.
      </p>
      <p>
        You can find information about the cookies and services used on this
        website in this privacy policy.
      </p>
      <h3>Server Log Files</h3>
      <p>
        The provider of the pages automatically collects and stores information
        in so-called server log files, which your browser automatically
        transmits to us. These are:
      </p>
      <ul>
        <li>Browser type and browser version</li>
        <li>Operating system used</li>
        <li>Referrer URL</li>
        <li>Host name of the accessing computer</li>
        <li>Time of the server request</li>
        <li>IP address</li>
      </ul>
      <p>A merging of this data with other data sources is not carried out.</p>
      <p>
        The collection of this data is based on Article 6(1)(f) of the GDPR. The
        website operator has a legitimate interest in the technically error-free
        presentation and optimization of its website - for this purpose, the
        server log files must be collected.
      </p>
      <h3>Contacting via Email, Phone, or Fax</h3>
      <p>
        When you contact us via email, phone, or fax, your inquiry including all
        resulting personal data (name, inquiry) will be stored and processed by
        us for the purpose of processing your request. We will not share this
        data without your consent.
      </p>
      <p>
        The processing of this data is based on Article 6(1)(b) of the GDPR if
        your inquiry is related to the performance of a contract or is necessary
        for the implementation of pre-contractual measures. In all other cases,
        the processing is based on our legitimate interest in the effective
        processing of inquiries directed to us (Article 6(1)(f) of the GDPR) or
        on your consent (Article 6(1)(a) of the GDPR) if requested; the consent
        can be revoked at any time.
      </p>
      <p>
        The data sent to us via contact inquiries will remain with us until you
        request us to delete it, revoke your consent for storage, or the purpose
        for data storage no longer applies (e.g., after completing the
        processing of your request). Mandatory legal provisions - especially
        retention periods - remain unaffected.
      </p>
      <h2>5. Analysis Tools and Advertising</h2>
      <h3>Google Analytics</h3>
      <p>
        This website uses functions of the web analytics service Google
        Analytics. The provider is Google Ireland Limited ("Google"), Gordon
        House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        Google Analytics uses so-called "cookies." These are text files that are
        stored on your computer and enable an analysis of your use of the
        website. The information generated by the cookie about your use of this
        website is usually transferred to a Google server in the USA and stored
        there.
      </p>
      <p>
        The storage of Google Analytics cookies and the use of this analysis
        tool are based on Article 6(1)(f) of the GDPR. The website operator has
        a legitimate interest in analyzing user behavior to optimize both the
        website's offerings and its advertising. If consent has been obtained
        (e.g., consent to store cookies), the processing is based solely on
        Article 6(1)(a) of the GDPR; consent can be revoked at any time.
      </p>
      <p>
        <strong>IP Anonymization</strong>
      </p>
      <p>
        We have enabled the IP anonymization function on this website. This
        means that your IP address will be truncated by Google within the
        European Union member states or other countries that are party to the
        Agreement on the European Economic Area before being transmitted to the
        USA. Only in exceptional cases will the full IP address be sent to a
        Google server in the USA and truncated there. On behalf of the operator
        of this website, Google will use this information to evaluate your use
        of the website, compile reports on website activities, and provide
        further services related to website usage and internet usage to the
        website operator. The IP address transmitted by your browser as part of
        Google Analytics will not be associated with any other data held by
        Google.
      </p>
      <p>
        <strong>Browser Plugin</strong>
      </p>
      <p>
        You can prevent the storage of cookies by adjusting your browser
        software settings; however, please note that if you do this, you may not
        be able to use all the features of this website to their full extent.
        You can also prevent Google from collecting and processing the data
        generated by the cookie and related to your use of the website
        (including your IP address) by downloading and installing the browser
        plugin available at the following link:{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>
        .
      </p>
      <p>
        <strong>Objection to Data Collection</strong>
      </p>
      <p>
        You can prevent the collection of your data by Google Analytics by
        clicking on the following link. An opt-out cookie will be set, which
        prevents the collection of your data on future visits to this website:{" "}
        <a href="#" data-ua="UA-72461553-1" class="gaoptout">
          Deactivate Google Analytics
        </a>
        .
      </p>
      <p>
        More information on how Google Analytics handles user data can be found
        in Google's privacy policy:{" "}
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/6004245?hl=de
        </a>
        .
      </p>
      <p>
        <strong>Contract Data Processing</strong>
      </p>
      <p>
        We have concluded a contract data processing agreement with Google and
        fully implement the strict requirements of the German data protection
        authorities when using Google Analytics.
      </p>
      <p>
        <strong>Data Retention</strong>
      </p>
      <p>
        Data stored by Google at the user and event level, linked to cookies,
        user IDs (e.g., User ID), or advertising IDs (e.g., DoubleClick cookies,
        Android advertising ID), is anonymized or deleted after 14 months. For
        more details, please refer to the following link:{" "}
        <a
          href="https://support.google.com/analytics/answer/7667196?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/7667196?hl=de
        </a>
        .
      </p>
      <h2>6. Newsletter</h2>
      <h3>Newsletter Data</h3>
      <p>
        If you would like to receive the newsletter offered on the website, we
        require your email address and information that allows us to verify that
        you are the owner of the specified email address and agree to receive
        the newsletter. Additional data is not collected or is collected on a
        voluntary basis. We use this data exclusively to send the requested
        information and do not pass it on to third parties.
      </p>
      <p>
        The processing of data entered into the newsletter registration form is
        based exclusively on your consent (Article 6(1)(a) of the GDPR). You can
        revoke your consent to the storage of the data, the email address, and
        their use for sending the newsletter at any time, for example, via the
        "Unsubscribe" link in the newsletter. The legality of the data
        processing that has already taken place remains unaffected by the
        revocation.
      </p>
      <p>
        The data you provide to us for the purpose of subscribing to the
        newsletter will be stored by us or the newsletter service provider until
        you unsubscribe from the newsletter, and after unsubscribing, they will
        be deleted from our newsletter distribution list or blocked. We reserve
        the right to delete or block email addresses from our newsletter
        distribution list at our discretion within the scope of our legitimate
        interest according to Art. 6(1)(f) of the GDPR.
      </p>
      <p>Data stored for other purposes with us remains unaffected by this.</p>
      <p>
        After you have unsubscribed from the newsletter distribution list, your
        email address may be stored in a blacklist by us or the newsletter
        service provider, if necessary, to prevent future mailings. The data
        from the blacklist will only be used for this purpose and will not be
        merged with other data. This serves both your interest and our interest
        in complying with legal requirements when sending newsletters
        (legitimate interest within the meaning of Art. 6(1)(f) of the GDPR).
        The storage in the blacklist is not time-limited.{" "}
        <strong>
          You can object to the storage if your interests outweigh our
          legitimate interest.
        </strong>
      </p>
      <h2>7. DATA PROCESSING FOR ORDER PROCESSING</h2>
      <p>
        To process your order, we cooperate with the following service providers
        who support us wholly or partially in the execution of concluded
        contracts. Certain personal data will be transmitted to these service
        providers in accordance with the following information.
      </p>
      <p>
        We pass on the personal data collected by us as part of the order
        processing to the transport company commissioned with the delivery, to
        the extent necessary for the delivery of the goods. We will share your
        payment information with the authorized credit institution in the
        context of payment processing, if this is necessary for payment
        processing. If payment service providers are used, we will explicitly
        inform you about this below. The legal basis for the transfer of data is
        Art. 6(1)(b) of the GDPR.
      </p>
      <h3>Data transfer to payment providers</h3>
      <p>
        The transmission of your data to payment providers is carried out
        exclusively via secure SSL encryption to the payment provider you
        selected in the ordering process for the purpose of payment processing.
      </p>
      <p>
        The transfer of your data to payment providers is based on Art. 6(1)(a)
        of the GDPR (consent) and Art. 6(1)(b) of the GDPR (processing to
        fulfill a contract). You have the possibility to revoke your consent to
        data processing at any time. Revoking your consent does not affect the
        effectiveness of data processing operations carried out in the past.
      </p>
      <h3>Use of Stripe as a payment service provider</h3>
      <p>
        On our website, we offer payment via Stripe and the associated payment
        methods (Paypal, Klarna, Mastercard, Visa, American Express). The
        provider of these payment services is:
      </p>
      <p>
        Stripe Payments Europe Ltd <br />
        Block 4, Harcourt Centre <br />
        Harcourt Road <br />
        Dublin 2
      </p>
      <p>
        When paying via Stripe, the payment information you enter will be
        transmitted to Stripe.
      </p>
      <p>
        The transmission of your data to Stripe is based on Art. 6(1)(a) of the
        GDPR (consent) and Art. 6(1)(b) of the GDPR (processing to fulfill a
        contract). You have the possibility to revoke your consent to data
        processing at any time. Revoking your consent does not affect the
        effectiveness of data processing operations carried out in the past. All
        data required for payment processing will be used exclusively for the
        execution of payments and transmitted via the "SSL" procedure. Stripe is
        PCI DSS certified. Stripe may transfer, process, and store personal data
        outside the EU, and it is subject to the Safe Harbor Agreement.
        Information about Stripe's privacy policy is available here:
        <a href="https://stripe.com/de/privacy">
          https://stripe.com/de/privacy
        </a>
        .
      </p>
      <h2>8. Plugins and Tools</h2>
      <h3>Google Fonts (local hosting)</h3>
      <p>
        This site uses so-called Google Fonts provided by Google for the uniform
        representation of fonts. The Google Fonts are installed locally; there
        is no connection to Google's servers.
      </p>
      <p>
        For more information about Google Fonts, visit:
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://developers.google.com/fonts/faq
        </a>
        and Google's privacy policy:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
      </p>
    </div>
  );
};

export default Privacy;
