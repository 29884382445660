import React from 'react'
import "./Cart.scss"
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeItem } from '../../redux/cartReducer';
import { loadStripe } from '@stripe/stripe-js';
import { makeRequest } from '../../makeRequest';


const Cart = () => {

    const products = useSelector(state => state.cart.products)
    const dispatch = useDispatch()
    const totalPrice = () => {
        let total = 0;
        products.forEach((item) => {
            total += item.quantity * item.price
        });
        return total.toFixed(2);
    };

    const stripePromise = loadStripe('pk_test_51NXr6kDqi19cTxvl7D8wRdeMtSwGdEhhKEkkJGHz2GE1zYoMDhPsADulL0VwpGv6iihLpvGn8OeEr5ZxoAAQoxp800ITorWGwm');

    const handlePayment = async() => {
        try {
            const stripe = await stripePromise;

            const res = await makeRequest.post("/orders", {
                products,
            });

            await stripe.redirectToCheckout({
                sessionId: res.data.stripeSession.id,
            });
        } catch (err) {
            console.log(err)
        }
    }

  return (
    <div className='cart'>
          <h2>Products in your Cart</h2>
          {products?.map((item) => (
              <div className="item" key={item.id}>
                  <img src={process.env.REACT_APP_UPLOAD_URL+item.img} alt="" />
                  <div className="details">
                      <h3>{item.title}</h3>
                      <div className="price">{item.quantity} x {item.price}€</div>
                  </div>
                  <DeleteOutlineSharpIcon className="delete" onClick={()=>dispatch(removeItem(item.id))} />
              </div>
          ))}
          <div className="total">
              <span>Subtotal</span>
              <span>{totalPrice()} €</span>
          </div>
          <button onClick={handlePayment}>Proceed to checkout</button>
    </div>
  )
}

export default Cart