import React from 'react'
import "./Imprint.scss"

const Imprint = () => {
  return (
    <div className='imprint'>
          <h1>Imprint</h1>
          <h2>Adrian Walenta Kunstvertrieb</h2>
          <h3>Postal address</h3>
          <p>Kässbohrerstr. 16 <br />89077 Ulm <br />Deutschland</p>
          <h3>Contact</h3>
          <p>phone: +49 176 6370 8475 <br />e-mail: info@dewaliere.com</p>
          <h3>More Information</h3>
          <p>Information according to § 36 VSBG</p>
          <p>In accordance with § 36 VSBG (Consumer Dispute Settlement Act - Act on Alternative Dispute Resolution in Consumer Matters), the operator of this website declares:</p>
          <p>We are neither willing nor obliged to participate in dispute resolution proceedings before a consumer arbitration board.</p>
          <p><span>Note</span></p>
          <p>The EU platform for out-of-court dispute resolution can be found online at: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
    </div>
  )
}

export default Imprint