import React, { useEffect, useState } from "react";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import "./Slider.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import useFetch from "../../hooks/useFetch";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const picture = [
    "/img/001.png",
    "/img/002.png",
    "/img/003.png",
    "/img/004.png",
    "/img/005.png",
    "/img/006.png",
  ];

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? picture.length - 1 : currentSlide - 1);
  };

  const nextSlide = () => {
    setCurrentSlide(currentSlide === picture.length - 1 ? 0 : currentSlide + 1);
  };

  return (
    <div className="slider">
      <div
        className="container"
        style={{
          transform: `translateX(-${currentSlide * (100 / picture.length)}%)`,
        }}
      >
        {picture.map((imgSrc, index) => (
          <img key={index} src={imgSrc} alt="" />
        ))}
      </div>
      <div className="icons">
        <div className="icon" onClick={prevSlide}>
          <KeyboardArrowLeftSharpIcon />
        </div>
        <div className="icon" onClick={nextSlide}>
          <KeyboardArrowRightSharpIcon />
        </div>
      </div>
      <div className="text">
        <div className="container">
          <h2>Division</h2>
          <h3>Limited collection now available</h3>
          <Link className="link" to="/shop">
            Shop now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Slider;
