import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="wrapper">
        <div className="top">
          <div className="left">
            <Link
              className="link"
              to="https://www.tiktok.com/@dewaliere"
              target="_blank"
            >
              TikTok
            </Link>
            <Link
              className="link"
              to="https://www.instagram.com/natandewaliere/"
              target="_blank"
            >
              Instagram
            </Link>
          </div>
          <div className="right">
            <img src="/img/icons/american-express.png" alt="amex" />
            <img src="/img/icons/mastercard.png" alt="mastercard" />
            <img src="/img/icons/visa.png" alt="visa" />
            <img src="/img/icons/klarna.png" alt="klarna" />
            <img src="/img/icons/paypal.png" alt="paypal" />
          </div>
        </div>
        <div className="center">
          <div className="item">
            <h4>Legal</h4>
            <Link className="link" to="/terms-conditions">
              Terms & Conditions
            </Link>
            <Link className="link" to="/return">
              Return Policy
            </Link>
            <Link className="link" to="/shipping">
              Shipping
            </Link>
            <Link className="link" to="/imprint">
              Imprint
            </Link>
            <Link className="link" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <div className="item">
            <h4>Explore</h4>
            <Link className="link" to="/">
              Home
            </Link>
            <Link className="link" to="/shop">
              Shop
            </Link>
            <Link className="link" to="/about">
              About
            </Link>
            <Link className="link" to="/contact">
              Contact
            </Link>
          </div>
          <div className="item">
            <h4>Newsletter</h4>
            <span>coming soon</span>
          </div>
        </div>
        <div className="bottom">
          <span className="copywright">© 2023 NATAN DE WALIÈRE.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
