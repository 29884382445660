import React, { useRef, useEffect } from 'react';
import "./Popups.scss";
import { Link } from 'react-router-dom';

const Cancelation = ({ onClick }) => {
  const containerRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        onClick();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [onClick]);

  return (
    <div className='popup'>
      <div className="container" ref={containerRef}>
              <h1>Something went wrong</h1>
              <p>Order could not be completed.</p>
              <Link className="link" to="/shop">Back to shop</Link>
      </div>
    </div>
  );
};

export default Cancelation;
