import React, { useState, useEffect, useRef } from "react";
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";
import { Link } from "react-router-dom";
import "./Header.scss";
import Cart from "../Cart/Cart";
import { useSelector } from "react-redux";

const Header = () => {
  const [open, setOpen] = useState(false);
  const products = useSelector((state) => state.cart.products);

  const [color, setColor] = useState(false);

  const headerRef = useRef(null);

  const changeColor = () => {
    if (window.scrollY >= 80) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    document.addEventListener("click", handleClickOutsideCart);

    return () => {
      window.removeEventListener("scroll", changeColor);
      document.removeEventListener("click", handleClickOutsideCart);
    };
  }, []);

  const handleClickOutsideCart = (e) => {
    if (headerRef.current && !headerRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div ref={headerRef} className={color ? "navbar navbar-bg" : "navbar"}>
      <div className="wrapper">
        <div className="left">
          <Link className="link" to="/">
            Natan de Walière
          </Link>
        </div>

        <div className="right">
          <div className="item">
            <Link className="link" to="/">
              Home
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/shop">
              Shop
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/about">
              About
            </Link>
          </div>
          <div className="item">
            <Link className="link" to="/contact">
              Contact
            </Link>
          </div>
          <div className="cartIcon" onClick={() => setOpen(!open)}>
            <ShoppingCartSharpIcon />
            <span>{products.length}</span>
          </div>
        </div>
      </div>
      {open && <Cart />}
    </div>
  );
};

export default Header;
