import React, { useState } from "react";
import "./Product.scss";
import AddShoppingCartSharpIcon from "@mui/icons-material/AddShoppingCartSharp";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import SEO from "../../components/SEO/SEO";

const Product = () => {
  const id = useParams().id;
  const [selectedImg, setSelectedImg] = useState("img");
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const { products, loading, error } = useFetch(`/products/${id}?populate=*`);

  return (
    <div className="product">
      {loading ? (
        "loading"
      ) : (
        <>
          <SEO title={products?.attributes?.title} />
          <div className="left">
            <div className="mainImg">
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  products?.attributes[selectedImg]?.data?.attributes?.url
                }
                alt=""
              />
            </div>
            <div className="images">
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  products?.attributes?.img?.data?.attributes?.url
                }
                alt=""
                onClick={(e) => setSelectedImg("img")}
              />
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  products?.attributes?.img2?.data?.attributes?.url
                }
                alt=""
                onClick={(e) => setSelectedImg("img2")}
              />
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  products?.attributes?.img3?.data?.attributes?.url
                }
                alt=""
                onClick={(e) => setSelectedImg("img3")}
              />
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  products?.attributes?.img4?.data?.attributes?.url
                }
                alt=""
                onClick={(e) => setSelectedImg("img4")}
              />
            </div>
          </div>
          <div className="right">
            <h1>{products?.attributes?.title}</h1>
            <div className="price">
              <span>{products?.attributes?.price} €</span>
              <span className="costs">
                <a target="blank" href="/shipping">
                  + shipping
                </a>
                <br />
                VAT not charged (§ 19 UStG)
              </span>
            </div>
            <p>{products?.attributes?.desc}</p>
            <div className="quantity">
              <button
                onClick={() =>
                  setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
                }
              >
                -
              </button>
              {quantity}
              <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
            </div>

            {/* <button
              className="add"
              onClick={() =>
                dispatch(
                  addToCart({
                    id: products.id,
                    title: products.attributes.title,
                    price: products.attributes.price,
                    img: products.attributes.img.data.attributes.url,
                    quantity,
                  })
                )
              }
            >
              <AddShoppingCartSharpIcon className="addIcon" />
              Add to cart
            </button> */}

            <button
              className="add"
              onClick={() => {
                if (products?.attributes?.button === "add to cart") {
                  dispatch(
                    addToCart({
                      id: products.id,
                      title: products.attributes.title,
                      price: products.attributes.price,
                      img: products.attributes.img.data.attributes.url,
                      quantity,
                    })
                  );
                }
              }}
            >
              {products?.attributes?.button === "add to cart" ? (
                <>
                  <AddShoppingCartSharpIcon className="addIcon" />{" "}
                  {products.attributes.button}
                </>
              ) : (
                products?.attributes?.button
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
