import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="notfound">
      <h1>Page not found</h1>
      <Link className="link" to="/">
        Go back to homepage
      </Link>
    </div>
  );
};

export default NotFound;
