import React from 'react'
import "./Card.scss"
import { Link } from 'react-router-dom'

const Card = ({item}) => {
    return (
        <Link className='link' to={`/shop/product/${item.id}`}>
            <div className='card'>
                <div className="image">
                    <img src={process.env.REACT_APP_UPLOAD_URL+item.attributes?.img?.data?.attributes?.url} alt="" className='mainImg'/>
                </div>
                <h3>{item?.attributes.title}</h3>
                <div className="prices">
                    <h4>{item?.attributes.price} €</h4>
                </div>
            </div>
        </Link>
  )
}

export default Card