import React, { useRef, useEffect } from "react";
import "./Popups.scss";

const Confirmation = ({ onClick }) => {
  const containerRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        onClick();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [onClick]);

  return (
    <div className="popup">
      <div className="container" ref={containerRef}>
        <h1>Thank you!</h1>
        <p>
          Your order was successful. You will receive a confirmation of receipt
          by email shortly.
        </p>
      </div>
    </div>
  );
};

export default Confirmation;
