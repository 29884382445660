import React from "react";
import "./FeaturedProducts.scss";
import Card from '../Card/Card';
import useFetch from "../../hooks/useFetch";


const FeaturedProducts = ({type}) => {



 const {products, loading, error} = useFetch(`/products?populate=*&[filters][type][$eq]=${type}`)

  return (
    <div className='featuredProducts'>
          <div className="top">
              <h2>{type}</h2>
          </div>
          <div className="bottom">
              {error ? "something went wrong" 
              :loading ? "loading"
              : products?.map((item) => 
                  <Card item={item} key={item.id} className="card"/>
              )}
          </div>
    </div>
  )
}

export default FeaturedProducts