import {createBrowserRouter,RouterProvider,Outlet} from "react-router-dom";
import Products from "./pages/Products/Products";
import Product from "./pages/Product/Product";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import "./App.scss"
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Shipping from "./pages/Shipping/Shipping";
import Return from "./pages/Return/Return";
import Imprint from "./pages/Imprint/Imprint";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import NotFound from "./pages/NotFound/NotFound";
import { HelmetProvider } from 'react-helmet-async';

const Layout = () => {
  return (
    <div className="app">
        <ScrollToTop>
          <HelmetProvider>
          <Header/>
          <Outlet/>
          <Footer />
          </HelmetProvider>
        </ScrollToTop>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home/>
      },
      {
        path: "/shop/product/:id",
        element: <Product/>
      },
      {
        path: "/shop",
        element: <Products/>
      },
      {
        path: "/about",
        element: <About/>
      },
      {
        path: "/contact",
        element: <Contact/>
      },
      {
        path: "/shipping",
        element: <Shipping/>
      },
      {
        path: "/return",
        element: <Return/>
      },
      {
        path: "/imprint",
        element: <Imprint/>
      },
      {
        path: "/privacy-policy",
        element: <Privacy/>
      },
      {
        path: "/terms-conditions",
        element: <Terms/>
      },
      {
        path: "*",
        element: <NotFound/>
      },
    ]
  }
])

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
