import React, { useState } from "react";
import Slider from "../../components/Slider/Slider";
import "./Home.scss";
import FeaturedProducts from "../../components/FeaturedProducts/FeaturedProducts";
import Cancelation from "../../components/Popups/Cancelation";
import Confirmation from "../../components/Popups/Confirmation";
import queryString from "query-string";
import SEO from "../../components/SEO/SEO";

const Home = () => {
  const { success } = queryString.parse(window.location.search);
  const isSuccess = success === "true";

  const [showCancelation, setShowCancelation] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(true);

  const handleCancelationClick = () => {
    setShowCancelation(false);
  };

  const handleConfirmationClick = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="home">
      <SEO
        title="NATAN DE WALIÈRE"
        description="Discover exclusive limited digital prints of artist Natan de Walière's mesmerizing artworks at our online shop. Elevate your space with these captivating pieces of art, available only in limited quantities. Explore the enchanting world of Natan de Walière and bring home a piece of artistic brilliance today. Shop now and embrace the beauty of limited edition art!"
      />
      <Slider />
      <FeaturedProducts type="popular picks" />
      <div className="container">
        <div className="intro">
          <h1>Where Fantasies Merge with Reality</h1>
          <img src="/img/Intro.png" alt="" />
        </div>
      </div>
      <FeaturedProducts type="latest collection" />
      {showCancelation && success === "false" ? (
        <Cancelation onClick={handleCancelationClick} />
      ) : null}
      {showConfirmation && success === "true" ? (
        <Confirmation onClick={handleConfirmationClick} />
      ) : null}
    </div>
  );
};

export default Home;
