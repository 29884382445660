import React from 'react'
import "./Return.scss"

const Return = () => {
  return (
    <div className='return'>
          <h1>return policy</h1>
          <h2>Right of withdrawal for consumers</h2>
          <p>(A consumer is any natural person who enters into a legal transaction for purposes that are predominantly outside his or her trade, business or profession).</p>
          <p>You have the right to cancel this contract within fourteen days without giving any reason. The revocation period is fourteen days from the day on which you or a third party named by you, who is not the carrier, has or has taken possession of the last goods.</p>
          <p>In order to exercise your right of withdrawal, you must inform us Adrian Walenta Kunstvertrieb, Kässbohrerstr. 16, 89077 Ulm, Deutschland, phone: 0176 6370 8475, e-Mail: info@dewaliere.com by means of a clear declaration (e.g. a letter or e-mail sent by post) of your decision to withdraw from this contract. </p>
          <p>You can use the attached <a href="#withdrawalform">sample withdrawal form</a> for this purpose, which is, however, not mandatory.</p>
          <p>To comply with the withdrawal period, it is sufficient that you send the notice of exercise of the right of withdrawal before the expiry of the withdrawal period.</p>
          <h2>Consequences of the revocation</h2>
          <p>If you revoke this contract, we shall reimburse you all payments we have received from you, including delivery costs (with the exception of additional costs resulting from the fact that you have chosen a type of delivery other than the most favorable standard delivery offered by us), without undue delay and no later than within fourteen days from the day on which we received the notification of your revocation of this contract. For this repayment, we will use the same means of payment that you used for the original transaction, unless expressly agreed otherwise with you; in no case will you be charged any fees because of this repayment.</p>
          <p>We may refuse repayment until we have received the goods back or until you have provided proof that you have returned the goods, whichever is the earlier. </p>
          <p>You must return or hand over the goods to us without undue delay and in any case no later than within fourteen days from the day on which you notify us of the revocation of this contract. The deadline is met if you send the goods before the expiry of the period of fourteen days. </p>
          <h2>Goods that can be sent by parcel post </h2>
          <p>You shall bear the direct costs for the return of the goods and, in the case of return shipment from non-EU countries, any additional fees incurred (e.g. customs duties and taxes).</p>
          <p>You only have to pay for any loss in value of the goods if this loss in value is due to handling of the goods that is not necessary for testing the condition, properties and functioning of the goods.</p>
          <h2 id='withdrawalform'>Sample withdrawal form</h2>
          <p>If you wish to revoke the contract, please fill out and return this form.</p>
          <p>To:</p>
          <p>Adrian Walenta Kunstvertrieb <br/>Kässbohrerstr. 16 <br/>89077 Ulm <br/>Deutschland</p>
          <p>I/we (*) hereby revoke the contract concluded by me/us (*) for the purchase of the following goods (*)/provision of the following service (*):</p>
          <p>Ordered on (*)/received on (*):</p>
          <p>Name of consumer(s):</p>
          <p>Address of consumer(s):</p>
          <p>Signature of consumer(s) (only if notice is on paper):</p>
          <p>Date:</p>
          <p>* please delete where not applicable</p>
    </div>
  )
}

export default Return